<template>
  <div class="kakao-login-page">redirect to...</div>
</template>

<script>
import { getKakaoAuthToken, cccvLogin } from "@/common/api.service";
import { setAuth } from "@/common/auth.service";

export default {
  name: "Kakao",
  async mounted() {
    try {
      const code = this.$route?.query?.code;
      if (!code) {
        throw new Error();
      }

      const kakaoAuth = await getKakaoAuthToken(code);
      if (kakaoAuth.data) {
        const accessToken = kakaoAuth.data.access_token;

        // code가 들어오면 API를 호출해서 CCCV에 로그인을 한다.
        const login = await cccvLogin("kakao", {
          access_token: accessToken
        });

        if (login && login.data) {
          setAuth(login.data);
          this.$router.push("/admin/user");
        }
      }
    } catch (err) {
      alert("로그인에 실패하였습니다.");
      window.location.href = "/";
    }
  }
};
</script>

<style></style>
